.email {
  height: 80px;
}

.password > :global(input.ant-input:focus),
.account > :global(input.ant-input:focus),
.emailAccount:focus {
  box-shadow: inset 0 0 0 1000px #fff !important;
}

.password > :global(input.ant-input),
.account > :global(input.ant-input),
.emailAccount {
  box-shadow: inset 0 0 0 1000px #F6F8FB !important;
}

.loginBottom{
  width: 48px;
  margin-top: 15px;
}

.loginBottom:nth-child(2){
  margin-left: 55px;
  margin-right: 55px;
}

.lable{
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .email {
    height: 76px;
  }
}

.dark_dropdown {
  background-color: red;
}

.menu {
  border-radius: 8px;
}

.dark_menu {
  background-color: #201F2D;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 50%);
}

.menuItem {
  height: 48px;
}

.dark_menuitem {
  color: #8E939F;
  position: relative;
}

.dark_menuitem:hover {
  background-color: transparent;
}
.dark_menuitem:hover > * {
  z-index: 3;
  color: #fff;
}
.dark_menuitem:hover::after {
  content: '';
  position: absolute;
  width: calc(100% - 16px);
  border-radius: 4px;
  height: 100%;
  left: 8px;
  background-color: #353444;
  z-index: 0;
}

.darkLabel {
  color: #7B7D90;
}