.otherLoginBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.otherLoginLine {
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 0.5px;
  margin: 0 10px;
}

.otherLoginText {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 17px;
}
.oAuthLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 960px) {
    padding-bottom: 48px;
  }
}

.darkOtherLoginText {
  color: #7B7D90;
}